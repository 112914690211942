import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  open(event) {
    event.preventDefault()
    this.modalTarget.style.display = "flex";
  }

  close(event) {
    if (event.target === this.modalTarget) {
      event.preventDefault()
      this.modalTarget.style.display = "none";
    }
  }
}
